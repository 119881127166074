<template>
  <v-container fluid id="home" class="container-class">

    <v-img class="logo-style" lazy-src="require('@/assets/Jata_Logo.png')" max-height="35%" max-width="35%" contain
      :src="require('@/assets/Jata_Logo.png')"></v-img>
    <h1 class="title-text-bm">Badan Kawalselia Air Johor</h1>
    <h1 class="title-text-bm">Badan Kawalselia Air Johor</h1>

    <h1 class="title-text-eng">Water Quality Telemetry Station</h1>



  </v-container>
</template>

<script>
  // -- import something here --

  export default {

    data: () => ({
    }),

    mounted() {
      console.log("welcome home");
      this.transition();
    },

    methods: {
      transition() {
        setTimeout(() => {
          this.$router.push({ path: '/PublicDisplay/Home' }).catch(() => { });
        }
          , 3000);
      }

    },

  };
</script>

<style lang="scss" scoped>
  @import '~scss/main';

  #home {
    position: fixed;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    background: white;
  }

  .logo-style {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
    top: -290px;
    bottom: 0;

  }

  .title-text-bm {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
    // bottom: 25vh;
    bottom: 230px;
    font-size: 90px;
  }

  .title-text-eng {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
    // bottom: 25vh;
    bottom: 100px;
    font-size: 90px;
  }

  .container-class {
    background-image: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), url("~@/assets/background_pd.jpg") !important;
    background-size: cover !important;
    background-position: 50% 50%;
    overflow-y: hidden;
    height: 100%;
    position: relative;
  }
</style>